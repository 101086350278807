<template>
  <div class="auth-wrapper auth-v2">
    <HTMLTitle :title="$t('register.page_title')"></HTMLTitle>
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <app-logo v-if="skin !== 'dark'" />
        <app-logo-dark v-if="skin === 'dark'" />
        <h2 class="brand-text text-primary ml-1">
          {{ appName }}
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img fluid :src="imgUrl" :alt="$t('register.page_title')" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title class="mb-1">
            {{ $t('register.title') }} 🚀
          </b-card-title>
          <b-card-text class="mb-2" v-html="$t('register.subtitle')">
          </b-card-text>

          <!-- form -->
          <validation-observer ref="registerForm" #default="{invalid}">
            <b-form class="auth-register-form mt-2" @submit.prevent>
              <!-- username -->
              <b-form-group label-for="register-username" :description="$t('register.name_description')">
                <validation-provider #default="{ errors }" :name="$t('common.name')" vid="username" rules="required|min:3">
                  <b-form-input id="register-username" v-model="form.name" name="register-username"
                    :state="errors.length > 0 ? false:null" :placeholder="$t('common.name')" maxlength="50" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- email -->
              <b-form-group label-for="register-email" :description="$t('register.email_description')">
                <validation-provider #default="{ errors }" :name="$t('common.email')" vid="email" rules="required|email">
                  <b-form-input id="register-email" v-model="form.email" name="register-email"
                    :state="errors.length > 0 ? false:null" :placeholder="$t('common.email')" maxlength="100" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-form-group label-for="register-password" :description="$t('register.password_description')">
                <validation-provider #default="{ errors }" :name="$t('common.password')" vid="password" rules="required|min:6">
                  <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid':null">
                    <b-form-input id="register-password" v-model="form.password" class="form-control-merge"
                      :type="passwordFieldType" :state="errors.length > 0 ? false:null" name="register-password"
                      :placeholder="$t('common.password')" maxlength="15"/>
                    <b-input-group-append is-text>
                      <feather-icon :icon="passwordToggleIcon" class="cursor-pointer" @click="togglePassword" />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- repeat password -->
              <b-form-group label-for="register-repeat" :description="$t('register.password_confirmation_description')">
                <validation-provider #default="{ errors }" :name="$t('common.password_confirmation')" vid="repeat_password" rules="required|confirmed:password">
                  <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid':null">
                    <b-form-input id="register-repeat" v-model="form.repeat" class="form-control-merge" :type="passwordConfirmationFieldType"
                      :state="errors.length > 0 ? false:null" name="register-repeat" :placeholder="$t('common.password_confirmation')" maxlength="15"/>
                    <b-input-group-append is-text>
                      <feather-icon :icon="passwordConfirmationToggleIcon" class="cursor-pointer" @click="togglePasswordConfirmation" />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <vue-recaptcha ref="recaptcha" @verify="onCaptchaVerified" @expired="onCaptchaExpired" size="invisible"
                :sitekey="recaptchaKey">
              </vue-recaptcha>

              <b-button variant="primary" block type="button" :disabled="invalid || formSubmitted" @click="preSubmit">
                <feather-icon v-if="!formSubmitted" size="16" icon="EditIcon" class="mr-50" />
                <b-spinner v-if="formSubmitted" class="mr-50" :type="spinnerType" style="width: 16px; height: 16px;" />
                <span class="align-middle">{{ $t('register.submit_button') }}</span>
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <span>{{ $t('register.already_registered') }}</span>
            <b-link :to="{name:'auth-login'}">
              <span>&nbsp;{{ $t('register.already_registered_link') }}</span>
            </b-link>
          </p>

          <simple-Navbar />

          <footer class="footer footer-light footer-static">
            <slot name="footer">
              <simple-footer />
            </slot>
          </footer>
        </b-col>
      </b-col>
      <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import AppLogo from '@core-md/layouts/components/Logo.vue'
import AppLogoDark from '@core-md/layouts/components/LogoDark.vue'
import {
  BRow, BCol, BLink, BButton, BSpinner, BForm, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BImg, BCardTitle, BCardText,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import VueRecaptcha from 'vue-recaptcha'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import HTMLTitle from '@core-md/layouts/components/HTMLTitle.vue'
import Recaptcha from '@core-md/utils/recaptcha'
import Crypt from '@core-md/utils/crypt'
import useJwt from '@core-md/services/auth/useJwt'
import defaultConfig from '@core-md/config'
import handleAxiosException from '@core-md/mixins/errors'

import { $themeConfig } from '@themeConfig'
import store from '@/store/index'

import SimpleNavbar from '@/layouts/components/SimpleNavbar.vue'
import SimpleFooter from '@/layouts/components/SimpleFooter.vue'

export default {
  components: {
    AppLogo,
    AppLogoDark,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BSpinner,
    BForm,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
    HTMLTitle,
    VueRecaptcha,
    SimpleNavbar,
    SimpleFooter,
  },
  mixins: [
    togglePasswordVisibility,
    handleAxiosException,
  ],
  data() {
    return {
      form: {
        name: '',
        email: '',
        password: '',
        repeat: '',
        recaptchaToken: '',
      },
      recaptchaKey: defaultConfig.RECAPTCHA_KEY,
      formSubmitted: false,
      passwordFieldType: 'password',
      passwordConfirmationFieldType: 'password',
      // validation
      required,
      email,
    }
  },
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app

    const { spinnerType } = $themeConfig.layout

    return {
      appName,
      appLogoImage,
      spinnerType,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordConfirmationToggleIcon() {
      return this.passwordConfirmationFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        return require('@/assets/images/pages/register-v2-dark.svg')
      }
      return require('@/assets/images/pages/register-v2.svg')
    },
    skin() {
      return store.state.appConfig.layout.skin
    },
  },
  mounted() {
    Recaptcha.setup()

    this.showModal()
  },
  methods: {
    preSubmit() {
      this.$refs.recaptcha.execute()
    },
    submitForm() {
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          this.formSubmitted = true
          const token = Crypt.signToken(this.form)
          const request = token
          useJwt.register({
            request,
          })
            .then(response => {
              const result = Crypt.decrypt(response.data.result)

              if (result.status
                && result.status === '0') {
                this.$root.showMessageSuccess(result.statusMessageDetail || result.statusMessage, this.$i18n.t('register.messages.success'))
                if (result.redirectTo) {
                  this.$router.push(result.redirectTo)
                }
              } else {
                this.$root.showMessageError(result.statusMessageDetail || result.statusMessage, this.$i18n.t('register.messages.error'))
              }
              this.formSubmitted = false
            })
            .catch(error => {
              this.formSubmitted = false
              this.handleAxiosException(error, 'register.messages.error')
            })
        }
      })
    },
    onCaptchaVerified(recaptchaToken) {
      this.$refs.recaptcha.reset()
      this.form.recaptchaToken = recaptchaToken
      this.submitForm()
    },
    onCaptchaExpired() {
      this.$refs.recaptcha.reset()
    },
    togglePassword() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
    },
    togglePasswordConfirmation() {
      this.passwordConfirmationFieldType = this.passwordConfirmationFieldType === 'password' ? 'text' : 'password'
    },
    showModal() {
      this.$swal({
        title: this.$i18n.t('register.modal.title'),
        html: this.$i18n.t('register.modal.text'),
        icon: 'info',
        confirmButtonText: this.$i18n.t('common.gotit'),
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        showClass: {
          popup: 'animate__animated animate__flipInX',
        },
        buttonsStyling: false,
      })
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
  @import '@core/scss/vue/pages/page-auth.scss';
</style>
