var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('HTMLTitle',{attrs:{"title":_vm.$t('register.page_title')}}),_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-link',{staticClass:"brand-logo"},[(_vm.skin !== 'dark')?_c('app-logo'):_vm._e(),(_vm.skin === 'dark')?_c('app-logo-dark'):_vm._e(),_c('h2',{staticClass:"brand-text text-primary ml-1"},[_vm._v(" "+_vm._s(_vm.appName)+" ")])],1),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",attrs:{"lg":"8"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-5"},[_c('b-img',{attrs:{"fluid":"","src":_vm.imgUrl,"alt":_vm.$t('register.page_title')}})],1)]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.$t('register.title'))+" 🚀 ")]),_c('b-card-text',{staticClass:"mb-2",domProps:{"innerHTML":_vm._s(_vm.$t('register.subtitle'))}}),_c('validation-observer',{ref:"registerForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-register-form mt-2",on:{"submit":function($event){$event.preventDefault();}}},[_c('b-form-group',{attrs:{"label-for":"register-username","description":_vm.$t('register.name_description')}},[_c('validation-provider',{attrs:{"name":_vm.$t('common.name'),"vid":"username","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-username","name":"register-username","state":errors.length > 0 ? false:null,"placeholder":_vm.$t('common.name'),"maxlength":"50"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"register-email","description":_vm.$t('register.email_description')}},[_c('validation-provider',{attrs:{"name":_vm.$t('common.email'),"vid":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-email","name":"register-email","state":errors.length > 0 ? false:null,"placeholder":_vm.$t('common.email'),"maxlength":"100"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"register-password","description":_vm.$t('register.password_description')}},[_c('validation-provider',{attrs:{"name":_vm.$t('common.password'),"vid":"password","rules":"required|min:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"register-password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false:null,"name":"register-password","placeholder":_vm.$t('common.password'),"maxlength":"15"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePassword}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"register-repeat","description":_vm.$t('register.password_confirmation_description')}},[_c('validation-provider',{attrs:{"name":_vm.$t('common.password_confirmation'),"vid":"repeat_password","rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"register-repeat","type":_vm.passwordConfirmationFieldType,"state":errors.length > 0 ? false:null,"name":"register-repeat","placeholder":_vm.$t('common.password_confirmation'),"maxlength":"15"},model:{value:(_vm.form.repeat),callback:function ($$v) {_vm.$set(_vm.form, "repeat", $$v)},expression:"form.repeat"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordConfirmationToggleIcon},on:{"click":_vm.togglePasswordConfirmation}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('vue-recaptcha',{ref:"recaptcha",attrs:{"size":"invisible","sitekey":_vm.recaptchaKey},on:{"verify":_vm.onCaptchaVerified,"expired":_vm.onCaptchaExpired}}),_c('b-button',{attrs:{"variant":"primary","block":"","type":"button","disabled":invalid || _vm.formSubmitted},on:{"click":_vm.preSubmit}},[(!_vm.formSubmitted)?_c('feather-icon',{staticClass:"mr-50",attrs:{"size":"16","icon":"EditIcon"}}):_vm._e(),(_vm.formSubmitted)?_c('b-spinner',{staticClass:"mr-50",staticStyle:{"width":"16px","height":"16px"},attrs:{"type":_vm.spinnerType}}):_vm._e(),_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(_vm.$t('register.submit_button')))])],1)],1)]}}])}),_c('p',{staticClass:"text-center mt-2"},[_c('span',[_vm._v(_vm._s(_vm.$t('register.already_registered')))]),_c('b-link',{attrs:{"to":{name:'auth-login'}}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('register.already_registered_link')))])])],1),_c('simple-Navbar'),_c('footer',{staticClass:"footer footer-light footer-static"},[_vm._t("footer",function(){return [_c('simple-footer')]})],2)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }